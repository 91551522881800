<script lang="ts" setup>
import { AisRefinementList } from "vue-instantsearch/vue3/es";
import { useProductListStore } from "~/stores/main";

const props = defineProps<{
  attribute: string;
  attributeName: string;
  isOpen?: boolean;
}>();

const { attribute } = toRefs(props);
const store = useProductListStore();
const isOpen = props.isOpen ? ref(true) : ref(false);

const paletteDotClasses = (color: string) => {
  const classes: string[] = [
    "rounded-full",
    "mr-2",
    "inline-block",
    "h-[24px]",
    "w-[24px]",
    "palette-dot",
  ];

  classes.push("bg-palette-" + color);

  return classes.join(" ");
};

const paletteItemClasses = (active: any) => {
  const classes: string[] = ["flex", "w-1/6", "palette-item", "mb-3"];

  if (active) classes.push("active");

  return classes.join(" ");
};

const refinePalette = (item: any) => {
  // console.log('refinePalette', item);
  if (!store.paletteFilters.get(window.location.pathname))
    store.paletteFilters.set(window.location.pathname, []);
  if (item.isRefined) {
    // remove the currently refined palette
    const arrayToUpdate = store.paletteFilters
      .get(window.location.pathname)
      .filter((value) => value !== item.value);
    store.paletteFilters.set(window.location.pathname, arrayToUpdate);
  } else {
    // add the currently refined palette
    store.paletteFilters.get(window.location.pathname).push(item.value);
  }
};

const getItemKey = (val: any) => {
  const key = props.attribute + "_" + val;
  return key
    .toLowerCase() // Convert to lowercase
    .normalize("NFD") // Normalize diacritic characters
    .replace(/[\u0300-\u036F]/g, "") // Remove diacritic marks
    .replace(/[^\w\s]/g, "") // Remove non-word characters (except spaces)
    .replace(/\s+/g, "_"); // Replace spaces with underscores
};
</script>

<template>
  <AisRefinementList
    v-slot="{ items, refine }"
    :attribute="attribute"
    :sort-by="['count:desc', 'name:asc']"
    operator="or"
  >
    <div v-if="items.length" class="bg-brand-surface rounded-30 p-4 text-15">
      <div
        class="flex flex-row cursor-pointer"
        @mousedown.prevent="isOpen = !isOpen"
      >
        <div class="basis-5/6 font-bold text-xl flex flex-row font-lora">
          <span>{{ attributeName }}</span>
        </div>
        <div class="basis-1/6 text-right">
          <ProductListFilterArrow :inverted="isOpen" />
        </div>
      </div>

      <div class="pt-3" :class="{ hidden: !isOpen }">
        <div class="flex flex-wrap">
          <div
            v-for="item in items"
            :key="getItemKey(item.value)"
            :class="paletteItemClasses(item.isRefined)"
            :disabled="item.count === 0"
            @click="
              refinePalette(item);
              refine(item.value);
              useScrollElementToView('productListContainer');
            "
          >
            <div :class="paletteDotClasses(item.value)"></div>
            <!--
          <div>
            {{ $t("palette." + item.label) }}
            <span class="ais-HierarchicalMenu-count">{{
              item.count.toLocaleString()
            }}</span>
          </div>
          --></div>
        </div>
      </div>
    </div>
  </AisRefinementList>
</template>

<style scoped>
.palette-item {
  /* these are to match the .ais-RefinementList-item styles */
  font-size: 0.875rem;
  line-height: 1.75rem;
  cursor: pointer;
}
.active .palette-dot {
  border: 2px solid black;
  outline: 2px #fff solid;
  outline-offset: -4px;
}
.bg-palette-white {
  border: 1px solid #222;
}
</style>
