<script lang="ts" setup>
import { viewItemList as sendViewItemListEvent } from "~/composables/useGoogleAnalytics";
import type { MasterProduct } from "~/types/common";
// const sortingOptions = [];

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const { status: authStatus, data: authData } = useAuth();

// Include Satellite theme.
// Fixme: we only use it now for hierarchical filter, should remove after making our own version of that.
useHead({
  style: [
    {
      href: "https://cdn.jsdelivr.net/npm/instantsearch.css@8.0.0/themes/satellite-min.css",
      integrity: "sha256-p/rGN4RGy6EDumyxF9t7LKxWGg6/MZfGhJM/asKkqvA=",
      crossorigin: "anonymous",
    },
  ],
});

const getUrlHash = computed(() => {
  const urlObject = new URL(window.location.href);
  return btoa(urlObject.toString());
});

// -----------------------
// reactive properties
// -----------------------
const themeGroup = config.public.theme.group;
const hasResults = ref(false);
const scrollPosition = useLocalStorage(
  "PLscrollPosition-" + getUrlHash.value,
  0,
);

const props = defineProps<{
  filters?: string;
  showOutOfStock?: boolean;
  query?: any;
  hideFacets?: Array<string> | null;
  title: string;
  beforeContent?: string | null;
  afterContent?: string | null;
}>();

const filterToggle = () => {
  const hiddenDiv = document.getElementById("productListFilters");
  if (hiddenDiv) hiddenDiv.classList.toggle("hidden");
};

const sortingRef = ref("sku:desc");
const filterContainerClasses = computed(() => {
  if (
    props.beforeContent &&
    props.afterContent &&
    props.beforeContent.length > 0 &&
    props.afterContent.length > 0
  ) {
    return "row-span-3";
  } else if (
    (props.beforeContent && props.beforeContent.length > 0) ||
    (props.afterContent && props.afterContent.length > 0)
  ) {
    return "row-span-2";
  }

  return "";
});

const extendedFilters = computed(() => {
  const parts = [];

  if (!props.showOutOfStock) {
    parts.push("stock > 0");
  }

  if (props.filters && props.filters.length > 0) {
    parts.push(props.filters);
  }

  return parts.join(" AND ");
});

const changeSorting = (newSorting: string) => {
  sortingRef.value = newSorting;
};

const handleSearchResultsUpdate = (products: MasterProduct[]) => {
  sendViewItemListEvent(products, { listName: props.title });
};

const priceFieldToUse = computed(() => {
  if (authStatus.value === "authenticated") {
    if (
      Array.isArray(authData?.value?.usergroups) &&
      authData?.value?.usergroups?.includes(1)
    ) {
      return "h";
    }
  }

  return "c";
});

const updateScrollY = () => {
  scrollPosition.value = window.scrollY;
  // console.log("scrollPosition", scrollPosition.value);
};

onMounted(() => {
  // console.log(props.filters);
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScrollY);
});

watch(
  () => hasResults.value,
  () => {
    // console.log("has results, scrolling to: ", scrollPosition.value);
    setTimeout(() => {
      window.scrollTo({
        top: scrollPosition.value,
        behavior: "instant",
      });
    }, 100);
    window.addEventListener("scroll", updateScrollY);
  },
);
</script>

<template>
  <ProductListSearchProvider :sorting="sortingRef">
    <ProductListConfigure :filters="extendedFilters" :query="props.query" />
    <div v-show="hasResults" id="productListContainer" class="px-3">
      <div class="flex flex-row items-center mb-3">
        <h1
          v-if="title && title.length > 0"
          class="!text-3xl font-bold leading-9 font-lora"
          style="margin-top: 0 !important"
        >
          {{ title }}
        </h1>
        <span class="mt-[7px] ml-3 text-nowrap"
          ><ProductListSearchStats
        /></span>
      </div>
      <div class="grid grid-cols-12 md:h-[40px]">
        <div class="col-span-12 md:col-span-6">
          <ProductListSearchBar class="hidden" />
          <div class="flex">
            <ProductListCurrentRefinements />
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div class="grid grid-cols-12">
            <div class="col-span-6 md:hidden">
              <i
                class="m-icon-filter text-2xl cursor-pointer"
                @click="filterToggle"
              ></i>
            </div>
            <div class="col-span-6 md:col-span-12">
              <ProductListSorting
                :sorting="sortingRef"
                @change-sorting="changeSorting"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="grid grid-cols-12 gap-4">
          <div
            class="col-span-12 md:col-span-3 xl:col-span-2"
            :class="filterContainerClasses"
          >
            <div
              id="productListFilters"
              class="filters hidden md:block mt-3 md:mt-0"
            >
              <ProductListHierarchicalFilter
                :attribute-name="$t('product_list.filters.kategoria')"
                class="mb-5"
                :attributes="['v.lvl0', 'v.lvl1', 'v.lvl2']"
                :is-open="true"
              />
              <ProductListFacetFilter
                v-if="!props.hideFacets?.includes('masterproduct_nem_property')"
                attribute="b"
                :attribute-name="$t('product_list.filters.nem')"
                :sort-by="['name:asc']"
                class="mb-5"
                :is-open="true"
              />
              <ProductListPriceFilter
                v-if="!props.hideFacets?.includes('masterproduct_price')"
                :attribute="priceFieldToUse"
                :attribute-name="$t('product_list.filters.ar')"
                class="mb-5"
                :is-open="true"
                :hide-in-action-filter="
                  props.filters?.includes('(c.in_action = True)')
                "
                :step="1"
              />
              <ProductListFacetFilter
                v-if="!props.hideFacets?.includes('product_meret_property')"
                attribute="e"
                :attribute-name="$t('product_list.filters.meret')"
                sort-by="spec_meret"
                class="mb-5"
                :is-open="true"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_gyarto_property',
                  ) && themeGroup !== 'tnf'
                "
                attribute="a"
                :attribute-name="$t('product_list.filters.gyarto')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListPaletteFilter
                v-if="!props.hideFacets?.includes('product_paletta_property')"
                attribute="d"
                :attribute-name="$t('color')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_felhasz_property',
                  )
                "
                attribute="r_a"
                :attribute-name="$t('product_list.filters.felhasznalas')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_tulajd_property',
                  )
                "
                attribute="s_a"
                :attribute-name="$t('product_list.filters.tulajdonsag')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_anyag_property',
                  )
                "
                attribute="f"
                :attribute-name="$t('product_list.filters.anyag')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListRangeFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_tomegl_property',
                  )
                "
                attribute="n_n"
                :attribute-name="$t('product_list.filters.tomeg')"
                class="mb-5"
                data-type="weight"
                :step="1"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_toltoany_property',
                  )
                "
                attribute="j"
                :attribute-name="$t('product_list.filters.toltoanyag')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_ujhossz_property',
                  )
                "
                attribute="k"
                :attribute-name="$t('product_list.filters.ujjhossz')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_talp_property',
                  )
                "
                attribute="l"
                :attribute-name="$t('product_list.filters.talp')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListRangeFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_urtartalom_property',
                  )
                "
                attribute="m_n"
                :attribute-name="$t('product_list.filters.urtartalom')"
                class="mb-5"
                data-type="capacity"
                :step="1"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_ferohelyl_property',
                  )
                "
                attribute="o"
                :attribute-name="$t('product_list.filters.ferohelyl')"
                :sort-by="['name:asc']"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_ferohely_property',
                  )
                "
                attribute="q"
                :attribute-name="$t('product_list.filters.ferohely')"
                :sort-by="['name:asc']"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_konflimit_property',
                  )
                "
                attribute="p"
                :attribute-name="$t('product_list.filters.konflimit')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_bejarat_property',
                  )
                "
                attribute="t"
                :attribute-name="$t('product_list.filters.bejarat')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_rudazat_property',
                  )
                "
                attribute="i"
                :attribute-name="$t('product_list.filters.rudazat')"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_atmero_property',
                  )
                "
                attribute="w"
                :attribute-name="$t('product_list.filters.atmero')"
                class="mb-5"
                sort-by="spec_atmero"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="
                  !props.hideFacets?.includes(
                    'masterproduct_termekjellemzo_oli_testmagas_property',
                  )
                "
                attribute="x"
                :attribute-name="$t('product_list.filters.testmagas')"
                :sort-by="['name:asc']"
                class="mb-5"
                :is-open="false"
              />
              <ProductListFacetFilter
                v-if="!props.hideFacets?.includes('available_in_stores')"
                attribute="u"
                :attribute-name="$t('product_list.filters.stock')"
                :sort-by="['name:asc']"
                class="mb-5"
                :is-open="false"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-9 xl:col-span-10">
            <div class="results">
              <div class="results-meta">
                <!-- <ProductListSearchStats /> -->
                <!-- <MeiliSearchSorting /> -->
              </div>
              <ProductListSearchResults
                :before-content="beforeContent"
                :after-content="afterContent"
                @update="handleSearchResultsUpdate"
                @has-items="hasResults = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ProductListSearchProvider>
</template>
