<script lang="ts" setup>
/*
  Reminder: for the "in_action" checkbox we ALWAYS use c.in_action, even if we actually generate the slider based on h.min_price, because
  the pricelist _4 works differently than pricelist _1 in SAP variant data, and there is no real "original_price" data in _4 pricelist, so the 
  h.in_action we calculate based on this is invalid. This is a mountex specific problem, ideally for the in_action checkbox we should use the same
  price field that we use for the slider: ${attribute}.in_action
*/
import { AisRangeInput, AisToggleRefinement } from "vue-instantsearch/vue3/es";
/* eslint-disable import/default */
import Slider from "@vueform/slider";
/* eslint-enable import/default */

const props = defineProps<{
  attribute: string;
  attributeName: string;
  isOpen?: boolean;
  step: number;
  hideInActionFilter?: boolean;
}>();

const config = useRuntimeConfig();
const { attribute } = toRefs(props);
const isOpen = props.isOpen ? ref(true) : ref(false);

const mV = (curr: any, range: any, ret = "all") => {
  let min = range.min;
  let max = range.max;
  if (curr.min) min = curr.min;
  if (curr.max) max = curr.max;

  if (ret === "min")
    return min.toLocaleString(config.public.locale, {
      style: "currency",
      currency: config.public.currency,
      maximumFractionDigits: 0,
    });
  if (ret === "max")
    return max.toLocaleString(config.public.locale, {
      style: "currency",
      currency: config.public.currency,
      maximumFractionDigits: 0,
    });

  return [min, max];
};

const tooltipFormat = (val: any) => {
  return val.toLocaleString(config.public.locale, {
    style: "currency",
    currency: config.public.currency,
    maximumFractionDigits: 0,
  });
};
</script>

<template>
  <div>
    <div class="bg-brand-surface rounded-30 p-4 text-15">
      <div
        class="flex flex-row cursor-pointer"
        @mousedown.prevent="isOpen = !isOpen"
      >
        <div class="basis-5/6 font-bold text-xl flex flex-row font-lora">
          <span>{{ attributeName }}</span>
        </div>
        <div class="basis-1/6 text-right">
          <ProductListFilterArrow :inverted="isOpen" />
        </div>
      </div>
      <div class="pt-5" :class="{ hidden: !isOpen }">
        <AisRangeInput :attribute="`${attribute}.price_min`">
          <template #default="{ currentRefinement, range, refine }">
            <div v-if="range">
              <Slider
                :min="range.min"
                :max="range.max"
                :model-value="mV(currentRefinement, range)"
                :step="props.step"
                :tooltips="true"
                show-tooltip="drag"
                :format="tooltipFormat"
                @change="refine({ min: $event[0], max: $event[1] })"
              />
              <div class="mt-5 grid grid-cols-12">
                <div class="col-span-5 bg-white text-center rounded-2xl">
                  {{ mV(currentRefinement, range, "min") }}
                </div>
                <div class="col-span-2 text-center">-</div>
                <div class="col-span-5 bg-white text-center rounded-2xl">
                  {{ mV(currentRefinement, range, "max") }}
                </div>
              </div>
            </div>
          </template>
        </AisRangeInput>

        <AisToggleRefinement
          v-if="!props.hideInActionFilter"
          attribute="c.in_action"
          class="mt-4"
        >
          <template #default="{ value, refine }">
            <BaseCheckbox
              key="price_in_action"
              :value="value.isRefined"
              :label="$t('in_action_products')"
              name="in_action_products"
              @change="
                refine(value);
                useScrollElementToView('productListContainer');
              "
            />
          </template>
        </AisToggleRefinement>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>
