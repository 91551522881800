<script lang="ts" setup>
import { AisHits } from "vue-instantsearch/vue3/es";
import { ref, watch } from "vue";
import { useProductListStore } from "~/stores/main";
import type { MasterProduct } from "~/types/common";

const props = defineProps<{
  beforeContent?: string | null;
  afterContent?: string | null;
}>();

const emit = defineEmits(["update", "hasItems"]);
const lastRefinedPalette = ref("");
const store = useProductListStore();

watch(
  () => JSON.stringify(store.paletteFilters?.get(window.location.pathname)),
  () => {
    // console.log(`Value for key "${window.location.pathname}" changed:`, newValue);
    const paletteFilters = store.paletteFilters.get(window.location.pathname);
    lastRefinedPalette.value = paletteFilters.length
      ? paletteFilters[paletteFilters.length - 1]
      : "";
  },
);

let lastChange = null;
const handleItems = (products: MasterProduct[]) => {
  nextTick(() => {
    lastChange = Date.now();
    const timeoutId = setTimeout(() => {
      if (Date.now() - lastChange < 500) {
        clearTimeout(timeoutId);

        return;
      }

      emit("update", products);
    }, 500);
  });
};

const { isRealContent } = useHelpers();
</script>

<template>
  <AisHits>
    <template #default="{ items }">
      {{ emit("hasItems") }}
      {{ handleItems(items) }}
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="isRealContent(props.beforeContent)"
        class="mb-5"
        v-html="props.beforeContent"
      ></div>
      <!--eslint-enable-->
      <div
        v-if="items.length"
        class="mb-5 bg-brand-surface rounded-30 p-2 md:p-8"
      >
        <div class="flex flex-wrap mb-5">
          <div
            v-for="product in items"
            :key="product.id"
            class="2xl:w-1/5 xl:w-1/4 lg:w-1/3 md:w-1/2 w-1/2 p-2 md:p-4"
          >
            <ProductListProductCard
              class="h-full"
              :product="product"
              :palette="lastRefinedPalette"
            />
          </div>
        </div>
        <ProductListPagination class="pt-3" />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="isRealContent(props.afterContent)"
        v-html="props.afterContent"
      ></div>
      <!--eslint-enable-->
    </template>
  </AisHits>
</template>
