<script lang="ts" setup>
import { AisConfigure } from "vue-instantsearch/vue3/es";

const props = defineProps<{
  filters?: any;
  query?: any;
}>();
</script>

<template>
  <ais-configure
    :filters="props.filters"
    :query="props.query"
    :hits-per-page.camel="60"
  />
</template>
