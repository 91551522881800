<script lang="ts" setup>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { AisInstantSearch } from "vue-instantsearch/vue3/es";

import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";

const props = withDefaults(
  defineProps<{
    sorting: string;
  }>(),
  {
    sorting: "sku:desc",
  },
);

const config = useRuntimeConfig();

const routingRef = ref({
  router: history({
    cleanUrlOnDispose: true,
  }),
  stateMapping: singleIndexMapping(
    `${config.public.meilisearchIndexMasterproducts}:${props.sorting}`,
  ),
});

const host = config.public.meilisearchHost;
const searchApiKey = config.public.meilisearchKey;
const options = { finitePagination: true, primaryKey: "id" };
const searchComponentVisible = ref(true);

const { searchClient } = instantMeiliSearch(host, searchApiKey, options);
</script>

<template>
  <AisInstantSearch
    v-if="searchComponentVisible"
    :routing="routingRef"
    :index-name="`${config.public.meilisearchIndexMasterproducts}:${props.sorting}`"
    :search-client="searchClient"
    :future="{
      preserveSharedStateOnUnmount: false,
      persistHierarchicalRootCount: false,
    }"
  >
    <slot name="default" />
  </AisInstantSearch>
</template>
