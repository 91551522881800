<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    size?: string;
    tag?: string;
  }>(),
  {
    size: "text-15",
    tag: "p",
  },
);

const textSizeClass = computed(() => `${toRef(props, "size").value}`);
</script>

<template>
  <component :is="tag" :class="textSizeClass">
    <slot name="default" />
  </component>
</template>
