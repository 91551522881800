<script lang="ts" setup>
import { AisHierarchicalMenu } from "vue-instantsearch/vue3/es";

const props = defineProps<{
  attributes: Array<string>;
  attributeName: string;
  isOpen?: boolean;
}>();

const isOpen = props.isOpen ? ref(true) : ref(false);
</script>

<template>
  <div class="bg-brand-surface rounded-30 p-4">
    <div
      class="flex flex-row cursor-pointer"
      @mousedown.prevent="isOpen = !isOpen"
    >
      <div class="basis-5/6 font-bold text-xl flex flex-row font-lora">
        <span>{{ attributeName }}</span>
      </div>
      <div class="basis-1/6 text-right">
        <ProductListFilterArrow :inverted="isOpen" />
      </div>
    </div>

    <div class="pt-3" :class="{ hidden: !isOpen }">
      <ais-hierarchical-menu :attributes="props.attributes" />
    </div>
  </div>
</template>
