<script lang="ts" setup>
interface Props {
  value: string;
  placeholder?: string;
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: "Search...",
  value: "",
});
defineEmits(["input"]);
const placeholder = toRef(props, "placeholder");
</script>

<template>
  <form class="search-form">
    <input
      type="search"
      :placeholder="placeholder"
      :value="value"
      class="w-full bg-white rounded border border-surface00 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
      @input="$emit('input', $event)"
    />
  </form>
</template>
