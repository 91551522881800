<script lang="ts" setup>
import { AisRefinementList } from "vue-instantsearch/vue3/es";
import { MeiliSearch } from "meilisearch";

const props = defineProps<{
  attribute: string;
  attributeName: string;
  sortBy?: Array<string> | string;
  isOpen?: boolean;
}>();

const isOpen = props.isOpen ? ref(true) : ref(false);

let sortedSizes = [];

if (props.sortBy === "spec_meret") {
  interface SearchParams {
    filter?: string;
    limit?: number;
    sort?: string[];
  }

  const config = useRuntimeConfig();
  const meiliSearchIndex = String(config.public.meilisearchIndexTaxa);
  const searchParams: SearchParams = {};

  // Initialize Meilisearch client
  const client = new MeiliSearch({
    host: String(config.public.meilisearchHost),
    apiKey: String(config.public.meilisearchKey),
  });
  const index = client.index(meiliSearchIndex);
  searchParams.filter = "taxonomy_id=" + parseInt(config.public.meilisearchSizeTaxonomyId);
  searchParams.limit = 9999;
  searchParams.sort = ["priority:asc"];

  const searchResult = await index.search("", searchParams);
  // console.log(searchResult);

  if (Array.isArray(searchResult.hits)) {
    sortedSizes = searchResult.hits.map((hit) => hit.name);
  }
  // console.log(sortedSizes);
}

const getSortBy = () => {
  if (typeof props.sortBy === "undefined") return ["count:desc", "name:asc"];

  if (props.sortBy === "spec_meret") {
    return (a, b) => {
      const ret = sortedSizes.indexOf(a.name) - sortedSizes.indexOf(b.name);
      return ret;
    };
  }

  if (props.sortBy === "spec_atmero") {
    return (a, b) => {
      const ret = parseInt(a.name) - parseInt(b.name);
      return ret;
    };
  }

  return props.sortBy;
};

const getItemKey = (val: any) => {
  const key = props.attribute + "_" + val;
  return key
    .toLowerCase() // Convert to lowercase
    .normalize("NFD") // Normalize diacritic characters
    .replace(/[\u0300-\u036F]/g, "") // Remove diacritic marks
    .replace(/[^\w\s]/g, "") // Remove non-word characters (except spaces)
    .replace(/\s+/g, "_"); // Replace spaces with underscores
};
</script>

<template>
  <AisRefinementList
    v-slot="{ items, isShowingMore, canToggleShowMore, refine, toggleShowMore }"
    :attribute="props.attribute"
    :sort-by="getSortBy()"
    operator="or"
    :limit="10"
    :show-more-limit="9999"
    :show-more="true"
  >
    <div v-if="items.length" class="bg-brand-surface rounded-30 p-4 text-15">
      <!--
      <BaseTitle class="mb-3 text-lg font-bold">
        {{ attributeName }}
      </BaseTitle>
      -->

      <div
        class="flex flex-row cursor-pointer"
        @mousedown.prevent="isOpen = !isOpen"
      >
        <div class="basis-5/6 font-bold text-xl flex flex-row font-lora">
          <span>{{ attributeName }}</span>
        </div>
        <div class="basis-1/6 text-right">
          <ProductListFilterArrow :inverted="isOpen" />
        </div>
      </div>

      <div class="pt-3" :class="{ hidden: !isOpen }">
        <BaseCheckbox
          v-for="item in items"
          :key="getItemKey(item.value)"
          :value="item.isRefined"
          :label="item.label"
          :name="getItemKey(item.value)"
          :disabled="item.count === 0"
          @change="
            refine(item.value);
            useScrollElementToView('productListContainer');
          "
        >
          <BaseText tag="span">
            {{ item.label }}
            <!--
          <BaseText tag="span">
            <span class="ais-RefinementList-count">{{
              item.count.toLocaleString()
            }}</span>
          </BaseText>
          -->
          </BaseText>
        </BaseCheckbox>

        <div
          v-if="canToggleShowMore"
          class="show-more text-brand-link text-right font-bold"
          @click="toggleShowMore"
        >
          {{ !isShowingMore ? $t("show_more") : $t("show_less") }}
        </div>
      </div>
    </div>
  </AisRefinementList>
</template>
