<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    sorting: string;
  }>(),
  {
    sorting: "sku:desc",
  },
);

const emit = defineEmits(["changeSorting"]);

const isOpen = ref(false);
const sortingDiv = ref(null);

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

// -----------------------
// other
// -----------------------
const handleClickOutside = (event) => {
  // Check if the clicked element is outside myDiv
  if (sortingDiv.value && !sortingDiv.value.contains(event.target)) {
    isOpen.value = false;
  }
};

const currentSortingLabel = () => {
  if (props.sorting === "c.price_min:desc")
    return "product_list.sort_price:desc";
  if (props.sorting === "c.price_min:asc") return "product_list.sort_price:asc";
  return "product_list.sort_" + props.sorting;
};
</script>
<template>
  <div ref="sortingDiv" class="relative focus:ring-0 focus:outline-none">
    <div class="flex justify-end">
      <div
        class="cursor-pointer focus:ring-0 focus:outline-none flex flex-row"
        @click="isOpen = !isOpen"
      >
        <div class="m-icon-sort mt-1 mr-2"></div>
        <div>{{ $t(currentSortingLabel()) }}</div>
      </div>
    </div>
    <div v-if="isOpen" class="absolute bg-white p-3 border right-0">
      <div
        class="cursor-pointer"
        @click="
          emit('changeSorting', 'sku:desc');
          isOpen = false;
        "
      >
        {{ $t("product_list.sort_sku:desc") }}
      </div>
      <div
        class="cursor-pointer"
        @click="
          emit('changeSorting', 'sku:asc');
          isOpen = false;
        "
      >
        {{ $t("product_list.sort_sku:asc") }}
      </div>
      <div
        class="cursor-pointer"
        @click="
          emit('changeSorting', 'c.price_min:desc');
          isOpen = false;
        "
      >
        {{ $t("product_list.sort_price:desc") }}
      </div>
      <div
        class="cursor-pointer"
        @click="
          emit('changeSorting', 'c.price_min:asc');
          isOpen = false;
        "
      >
        {{ $t("product_list.sort_price:asc") }}
      </div>
      <div
        class="cursor-pointer"
        @click="
          emit('changeSorting', 'full_name:asc');
          isOpen = false;
        "
      >
        {{ $t("product_list.sort_full_name:asc") }}
      </div>
      <div
        class="cursor-pointer"
        @click="
          emit('changeSorting', 'full_name:desc');
          isOpen = false;
        "
      >
        {{ $t("product_list.sort_full_name:desc") }}
      </div>
    </div>
  </div>
</template>
